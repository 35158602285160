export const SET_SECURITY_QUESTIONS = "set_security_questions";
export const ERROR_ACTION = "set_and_display_error";
export const SET_REGISTRATION_RESPONSE = "set_registration_response";
export const SET_USER_SESSION = "set_user_session";
export const LOGOUT_CLEAR_USER_SESSION = "logout_clear_user_session";
export const SET_CAPTCHA_IMAGE = "set_captcha_image";
export const SET_ADDRESS_INFO = "set_address_info";
export const SET_EXPERIENCE_INFO = "SET_EXPERIENCE_INFO";
export const PERSONAL_INFORMATION = " PERSONAL_INFORMATION";
export const SET_OTHER_INFORMATION = "set_other_information";
export const SET_QUALIFICATION_INFORMATION = "set_qualification_information";
export const CLEAR_QUALIFICATION_INFORMATION =
    "clear_qualification_information";
export const ADD_QUALIFICATION = "add_qualification";
export const ADD_EXPERIENCEINFO = "add_experienceinfo";
export const EDIT_EXPERIENCEINFO = "edit_experienceinfo";
export const EDIT_QUALIFICATION = "edit_qualification";
export const IS_QUALIFICATION_TYPE_CHANGED_BY_DROPDOWN =
    "is_qualification_type_changed_by_dropdown";
export const SET_EXPERIENCEINFO_EDIT_FLAG = "set_experienceinfo_flag";
export const EDIT_QUALIFICATION_DONE_RESET = "edit_qualification_done_reset";
export const SET_CURRENT_EDIT_QUALIFICATION_INDEX =
    "set_current_edit_qualification_index";
export const SET_DISPLAYABLE_QUALIFICATION_LIST =
    "set_displayable_qualification_list";
export const SET_PROFILE_CREATION_LOOKUPS = "set_profile_creation_lookups";
export const SET_STATES = "set_states";
export const SET_DISTRICTS = "set_districts";
export const SET_DISTRICTS_CORRESPONDENCE = "set_district_correspondence";
export const SET_TALUKAS = "set_talukas";
export const SET_TALUKAS_CORRESPONDENCE = "set_talukas_correspondence";
export const SET_VILLAGES = "set_villages";
export const SET_VILLAGES_CORRESPONDENCE = "set_villages_correspondence";
export const SET_ISSUING_DISTRICTS = "set_issuing_districts";
export const SET_PERSONAL_INFO = "set_personal_info";
export const SET_QUALIFICATION_TYPES = "set_qualification_types";
export const SET_QUALIFICATION_NAMES = "set_qualification_names";
export const SET_SUBJECTS = "set_subjects";
export const SET_UNIVERSITIES = "set_universities";
export const SHOW_LOADER = "show_loader";
export const HIDE_LOADER = "hide_loader";
export const SET_SELECTED_QUALIFICATION_TYPE =
    "set_selected_qualification_type";
export const SET_UPLOADED_PHOTO = "set_uploaded_photo";
export const SET_IMAGE_SRC = "set_image_src";
export const SET_CROPE_IMAGE_SRC = "set_crope_image_src";
export const SET_SIGNATURE_SRC = "set_signature_src";
export const SET_CROP_SIGNATURE_SRC = "set_crop_signature_src";
export const SET_IMAGE_SIGNATURE_FLAG = "set_image_signature_flag";
export const SET_PROFILE_STATUS = "set_profile_status";
export const SET_A_SUBMITTABLE_FLG = "set_a_submittable_flg";
export const SET_DISABLE_ALL_TAB_SAVE = "set_disable_all_tab_save";
export const SET_GET_EMAIL_OTP_RESPONSE = "set_get_email_otps_response";
export const SET_GET_MOBILE_OTP_RESPONSE = "set_get_mobile_otps_response";
export const SET_FORGOT_PASSWORD_STATE = "set_forgot_password_state";
export const SET_ADVERTISEMENT_LIST = "set_advertisement_list";
export const SET_ADVERTISEMENT_DETAILS = "set_advertisement_details";
export const SET_ADVERTISEMENT_SHOW_DETAILS = "set_advertisement_show_details";
export const SET_APPLICATION_ELIGIBILITY_INFO =
    "set_application_elgibility_info";
export const SET_APPLICATION_RE_CHECK_ELIGIBILITY_INFO = "set_application_re_check_eligibility_info";
export const SET_APPLICATION_POST_PREF_LIST_RE_CHECK_ELIGIBILITY = "set_application_post_pref_list_re_check_eligibility";
export const SET_APPLICATION_POST_PREF_LIST = "set_application_post_pref_list";
export const SET_APPLICATION_IS_REQST_IN_PROGRESS =
    "set_application_is_reqst_in_progress";
export const SET_PROCEED_TO_PAYMENT = "set_proceed_to_payment";
export const SET_GOTO_MYACCOUNT = "set_goto_myaccount";
export const SET_APPLICATION_ID="set_application_id";
export const SET_PROFILE_INFO_DISPLAY = "set_profile_info_display";
export const SET_API_REPO_VERSION = "set_api_repo_version";
export const SET_MY_APPLICATION_LIST = "set_my_application_list";
export const SET_UPLOADED_DOCUMENT = "set_uploaded_document";
export const SET_UPLOAD_DOCUMENT_INFO_ID = "set_upload_document_info_id";
export const SET_HAS_PENDING_TRANSACTIONS = "set_has_pending_transactions";
export const SET_CHANGE_PASS_MODAL = "set_change_pass_modal";
export const SET_PROFILE_SUMMARY_INFO = "set_profile_summary_info";
export const SET_UPDATE_PROFILE_MODAL = "set_update_profile_modal";
export const SET_APPLICATION_DETAILS = "set_application_details";
export const SET_SHOW_APPLICATION_DETAILS_FLAG ="set_show_pplication_details_flag";
export const SET_PRINT_PROFILE = "set_print_profile";
export const SET_PROFILE_VIEW = "set_profile_view";
export const SET_EMAIL_MOBILE_OTP = "set_email_mobile_otp";
export const SET_VERIFY_OTP_FLAG_EMAIL ="set_verify_otp_flag_email";
export const SET_VERIFY_OTP_FLAG_MOBILE = "set_verify_otp_flag_mobile";
export const SET_APPLICATION_RECEIPT = "set_application_receipt";
export const SET_SPORT_DETAILS_LIST = "set_sport_details_list";
export const EDIT_SPORT_DETAILS_LIST = "edit_sport_details_list";
export const SET_VERIFY_OTP_FLAG_LOGIN = "set_verify_otp_login";
export const SET_VERIFY_OTP_FLAG_FORGOT_PASSWORD = "set_verify_otp_forgot_password";
export const CLEAR_SPORT_DTO = "clear_sport_dto";
export const SET_USER_LOGIN_DATA = "set_user_login_data";
export const SET_UPDATE_PROFILE_DONE = "set_update_profile_done";
export const SET_BACK_TO_MY_ACCOUNT ="set_back_to_my_account";
export const SET_GET_USERNAME_OTP_RESPONSE = "set_get_username_otp_response";
export const SET_SECURITY_QUESTIONS_FORGOT_PASSWORD = "set_security_question_forgot_password";
export const SET_VERIFY_OTP_FLAG_REGISTRATION_MOBILE = 'set_verify_otp_flag_registration_mobile';
export const SET_VERIFY_OTP_FLAG_REGISTRATION_EMAIL = 'set_verify_otp_flag_registration_email';
export const SET_GUIDELINE_LINKS = "set_guideline_links";
export const SET_VERIFY_DETAILS = "set_verify_details";
export const SET_VERIFY_OTP_FLAG_FORGOT_EMAIL = "set_verify_otp_flag_forgot_email";
export const SET_VERIFY_OTP_FLAG_FORGOT_MOBILE = "set_verify_otp_flag_forgot_mobile";
export const SET_CHANGE_CENTER_FLAG = "set_change_center_flag";
export const SET_PAYMENT_HISTORY_MODAL = "set_payment_history_modal";
export const SET_PAYMENT_HISTORY_LIST = "set_payment_history_list";
export const SET_UPDATE_POST_PREF_FLAG = "set_update_post_pref_flag";
export const SET_EXAM_CENTER_LIST= "set_exam_center_list";
export const SET_CHECK_ELIGIBILITY_DISABLE= "set_check_eligibilty_disable";
export const SET_CORRESPONDENCE_DATA = "set_correspondence_data";
export const SET_APPLICATION_STAGE_USERDATA = "set_application_stage_userdata";
export const SET_SECURITY_QUESTIONS_FOR_RESET_EMAIL = "set_security_questions_for_reset_email";
export const SET_SECURITY_ANSWERS_FOR_RESET_EMAIL = "set_security_answers_for_reset_email";
export const SET_SECURITY_QUESTIONS_FOR_LOGIN = "set_security_questions_for_login";
export const SET_SECURITY_ANSWERS_FOR_LOGIN = "set_security_answers_for_login";
export const SET_MODULE_ENABLE_DISABLE = "set_module_enable_disable";
export const SET_API_CALL_FOR_MODULE = "set_api_call_for_module";
export const SET_MODULE_LIST = "set_module_list";
export const SET_EXPERIENCE_TABLE_LIST = "set_experience_table_list";
export const SET_LOG_OUT_OTP_DATA = "sel_logout_otp_data";
export const SET_MARKSHEET_LIST = "set_marksheet_list";
export const SET_RETOTALING_POST_SUBJECTS = "set_retotaling_post_subjects";
export const SET_RETOTALING_REQUESTS = "set_retotaling_requests";
export const SET_RETOTALING_REQUEST_ID = "set_retotaling_request_id";
export const SET_EASEBUZZ_ACCESS_KEY="set_easebuzz_access_key";
export const GET_OTP_DATA_EMAIL = "get_otp_data_email";
export const GET_OTP_DATA_MOBILE = "get_otp_data_mobile";
export const GET_OTP_DATA_USERNAME = "get_otp_data_username";
export const SET_POST_PREFERENCE_DETAILS = "set_post_preference_details";
export const SET_ADDITIONAL_RES_ENV_DATA = "set_additional_res_env_data";
export const SET_ALL_DEPARTMENTS_DATA = "set_all_departments_data";
export const SET_ALLDEPARTMENTS_DATA = "set_alldepartments_data";
export const SET_ALL_DISABILITIES_DATA = "set_all_disabilities_data";
export const GET_ALL_CANDIDATE_INFO = "get_all_candidate_info";
export const GET_OBJ_REQ_DATA="get_obj_req_data";
export const GET_DROPDOWN_OBJ_REQ = "get_dropdown_obj_req";
export const SET_OBJREQ = "set_objreq";
export const SET_ALL_OBJREQ = "set_all_objreq";
export const SET_OBJ_REQ_ID = "set_obj_req_id";
export const GET_POST_OBJREP = "get_post_objrep";
export const GET_OBJECTION_QUESTIONS = "get_objection_questions";
export const SET_PUBLICATION_TYPE_DATA = "set_publication_type_data";
export const SET_IS_MOBILE_NO_ALREADY_EXIST_FLG = "set_is_mobile_no_already_exist_flg";
export const SET_IS_EMAIL_ID_ALREADY_EXIST_FLG = "set_is_email_id_already_exist_flg";
export const SET_ADDITIONAL_QUESTIONS = "set_addtional_questions";
export const GET_NAME_OF_INDEXING_BODIES = "get_name_of_indexing_bodies";
export const GET_TYPE_OF_ARTICLES = "get_type_of_articles";
export const GET_ALL_JOB_DESIGNATION_LIST = "get_all_job_designation_list";
export const SET_EXAM_DISPLAY_NAME_FOR_ADMIT_CARD = "set_exam_display_name_for_admit_card";
export const GET_LOGIN_STATUS="get_login_status";
export const SET_DOWNLOAD_INTERVIEW_PT_LETTER_DATA="set_download_interview_pt_letter_data";
export const SET_DOCUMENT_SCRUTINY_STATUS_STAGE_DETAILS="set_document_scrutiny_status_stage_details";
export const SET_CONFIGURATION_ENABLE_UDID_VALIDATION_FLAG = "set_configuration_enable_udid_validation_flag";
export const SET_UDID_FLAG = "set_udid_flag";
export const SET_UDID_RESPONSE_MESSAGE = "set_udid_response_message";